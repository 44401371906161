body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.testimonials .slick-arrow.slick-next {
  right: 57px !important;
  top: 0;
  z-index: 10;
}

.testimonials .slick-arrow.slick-prev {
  right: 100px;
  left: auto !important;
  top: 0;
  z-index: 10;
}

.contact_btn_form {
  width: 180px;

  margin-top: 10px;
  margin-left: 230px;
  /* width: 400px; */
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #ccc;
  cursor: no-drop
}