@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Roboto:300,400,500,700");

/* .activeNavbar {
  background-color: red;
} */

.activeNavbar2 {
  color: blue
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.2;
  font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: #253858;
}

p,
.paragraph {
  font-weight: 400;
  color: #253858;
  font-size: 15px;
  line-height: 1.8;
  font-family: "Poppins", serif;
}

p-lg,
.paragraph-lg {
  font-size: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #253858;
  font-family: "Poppins", serif;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 80px;
}

@media (max-width: 991px) {

  h1,
  .h1 {
    font-size: 65px;
  }
}

@media (max-width: 991px) {

  h2,
  .h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 425px) {
  .slick-arrow {
    display: none !important;
  }
}

h3,
.h3 {
  font-size: 40px;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 14px;
}

.radius {
  border-radius: 8px;
}

/* Button style */
.btn {
  font-family: "Poppins", serif;
  text-transform: capitalize;
  padding: 12px 30px;
  border-radius: 75px;
  font-weight: 600;
  border: 0;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
}

.staticHeader {
  position: absolute;
  width: 100%;
  height: 120px;
  background: #0785c6;
  top: 0px;
  left: 0;
  right: 0;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-sm {
  font-size: 15px;
  padding: 10px 30px;
}

.btn-xs {
  padding: 5px 10px;
  font-size: 15px;
}

.btn-primary {
  background: #0785c6;
  color: #fff !important;
}

.btn-primary:active {
  background: #0785c6 !important;
}

.btn-primary:hover {
  background: #0785c6;
}

.btn-primary.focus,
.btn-primary.active {
  background: #3da5db !important;
  box-shadow: none !important;
}

.btn-transparent {
  background: transparent;
  color: #0785c6;
  font-weight: bold;
}

.btn-transparent:active,
.btn-transparent:hover {
  color: #0785c6;
}

body {
  background-color: #fff;
  overflow-x: hidden;
}

::-moz-selection {
  background: #0785c6;
  color: #fff;
}

::selection {
  background: #0785c6;
  color: #fff;
}

.marginTopSection {
  margin-top: 120px;
}

.margin2section {
  margin-top: 250px;
}

/* Technology menu box */

.field {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #253858;
}

ul.dropdown-menu {
  border-top: 0;
  flex-wrap: wrap;
  box-shadow: 0 30px 80px rgb(0 0 0/14%) !important;
  transition: ease-in-out 0.5s;
  border-radius: 8px !important;
  border: 0 !important;
  position: relative;
}

.dropdown-menu:after {
  position: absolute;
  content: "";
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-menu:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  height: calc(0px + 5px);
}

ul.dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
  padding: 35px;
  top: unset;
}

ul.dropdown-menu li a.dropdown-item {
  color: #7d828c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  transition: 0.5s;
  padding-left: 0;
}

ul.dropdown-menu li a.dropdown-item i {
  margin-right: 10px;
}

ul.dropdown-menu li li {
  line-height: 28px;
}

ul.dropdown-menu li a.dropdown-item:hover,
ul.dropdown-menu li a.dropdown-item.active {
  background: transparent;
  color: #0083fe;
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

li:hover ul.dropdown-menu li a {
  background: transparent;
}

li a.nav-link::after {
  display: none;
}

li:hover ul.dropdown-menu {
  display: flex !important;
}

.dropdown-col-2 ul.dropdown-menu {
  width: 500px;
}

.dropdown-col-2 ul.dropdown-menu li {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.dropdown-col-3 ul.dropdown-menu {
  width: 500px;
}

.dropdown-col-3 ul.dropdown-menu li {
  flex: 0 0 33.33% !important;
  max-width: 33.33% !important;
}

.dropdown-col-2 ul.dropdown-menu>li:nth-child(n + 3) {
  margin-top: 25px;
}

ul.dropdown-menu li .dropdown-item-title {
  color: #0785c6;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
}

nav li:hover ul.dropdown-menu li {
  width: 100%;
}

ul.dropdown-menu li ul.dropdwn-sub-item {
  padding: 0;
  margin-top: 10px;
  margin-left: 18px;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a.text-dark:hover {
  color: #0785c6 !important;
}

a:hover {
  color: #0785c6;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 35px;
}

.card-header-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 25px;
  margin-top: 45px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: #0785c6 !important;
}

.bg-gray {
  background: #eaeaea !important;
}

.bg-in-sky {
  background: #dceeff !important;
}

.text-primary {
  color: #0785c6 !important;
}

.text-color {
  color: #253858;
}

.text-dark {
  color: #000 !important;
}

.text-light {
  color: #999999 !important;
}

.-ml-30 {
  margin-left: -30px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.-mt-10 {
  margin-top: -10px !important;
}

.-mt-50 {
  margin-top: -50px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.rounded-lg {
  border-radius: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.font-secondary {
  font-family: "Poppins", serif !important;
}

.font-tertiary {
  font-family: "Poppins", serif !important;
}

.shadows {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  /*box-shadow: 0px 18px 39.1px 6.9px rgba(224, 241, 255, 0.34) !important;*/
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  /*box-shadow: 0px 18px 39.1px 6.9px rgba(224, 241, 255, 0.34) !important;*/
}

.bg-dark {
  background-color: #253858 !important;
}

/* icon */
.icon {
  font-size: 45px;
}

.icon-bg {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}

.icon-light {
  color: #c8d5ef;
}

/* /icon */
/* slick slider */
.slick-dots {
  text-align: center;
  padding-left: 0;
}

.slick-dots li {
  display: inline-block;
  margin: 2px;
}

.slick-dots li.slick-active button {
  width: 25px;
}

.slick-dots li button {
  height: 6px;
  width: 12px;
  background: rgba(255, 255, 255, 0.5);
  color: transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s ease;
  border: 0;
}

/* /slick slider */
/* form */
/*.form-control {
    background-color: #1185c6;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #4eb5e0;
    height: 60px;
    color: #fff;
}
.form-control:focus {
    border-color: #0785c6;
    outline: 0;
    box-shadow: none !important;
}*/

/*textarea.form-control {
    height: 120px;
}*/
/*input::webkit-input-placeholder,textarea::webkit-input-placeholder {
    color:#FFF !important;
}*/
input::placeholder {
  color: rgb(177, 175, 175) !important;
  font-size: 5px sans-serif;
}

/*textarea.form-control {
    height: 100px;
}*/
/* /form */
/* card */
.card {
  border: 0;
}

.card-header {
  border: 0;
}

.card-footer {
  border: 0;
}

/* /card */
.navigation {
  padding: 10px 130px;
  background: #ffffff;
  transition: 0.3s ease;
  box-shadow: 0px 0px 20px -8px black;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(7, 133, 198);
  /* font-weight: 600; */
}

.fixed-navbar {
  background-color: rgb(242, 252, 255);
  border-top: 2px solid #278de742;
  border-bottom: 2px solid #278de742;
  height: 65px;
  font-weight: 700;
  margin-top: 20px;
  z-index: 100;
  top: 55px;
  position: sticky;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #0785c6;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('../images/home/toggle.webp');
}

.navigation.nav-bg {
  background-color: #ffffff;
  padding: 0px 100px;
}

@media (max-width: 991px) {
  .navigation.nav-bg {
    padding: 0px 20px;
  }
}

@media (max-width: 991px) {
  .navigation {
    padding: 20px;
    background: #ffffff;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: relative;
  }
}

/* @media (min-width: 1074px) and (max-width: 1181px) {
  .navbar .nav-item .nav-link {
    font-family: "Poppins";
    text-transform: uppercase;
    padding: 12px;
    font-size: 13px;
}
} */

.navbar-expand-lg .navbar-nav .dm_width {
  width: 280px;
}

@media (min-width: 0px) and (max-width: 992px) {
  .navbar-expand-lg .navbar-nav .dm_width {
    width: 980px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .navbar .nav-item .nav-link {
    font-family: "Poppins";
    /* text-transform: uppercase; */
    padding: 8px !important;
    font-size: 10px;
  }
}

@media (min-width: 1201px) and (max-width: 1328px) {
  .navbar .nav-item .nav-link {
    font-family: "Poppins";
    /* text-transform: uppercase; */
    padding: 8px !important;
    font-size: 10px;
  }
}

@media (min-width: 1441px) and (max-width: 1528px) {
  .navbar .nav-item .nav-link {
    font-family: "Poppins";
    /* text-transform: uppercase; */
    padding: 12px !important;
    font-size: 12px;
  }
}

.navbar .nav-item .nav-link {
  font-family: "Poppins", serif;
  /* text-transform: uppercase; */
  padding: 15px;
}

.navbar .nav-item.active {
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link {
  color: #2f2c2c;
}

.hero-area {
  padding: 250px 0 370px;
  position: relative;
}

@media (max-width: 767px) {
  .hero-area {
    padding: 250px 0 150px;
    overflow: hidden;
  }
}

.hero-area h1 {
  position: relative;
  z-index: 2;
}

.layer {
  position: absolute;
  z-index: 1;
}

#l1 {
  bottom: 0;
  left: 0;
}

#l2 {
  top: 190px;
  left: -250px;
}

#l3 {
  top: 200px;
  left: 40%;
}

#l4 {
  top: 200px;
  right: 40%;
}

#l5 {
  top: 100px;
  right: -150px;
}

#l6 {
  bottom: -20px;
  left: 10%;
}

#l7 {
  bottom: 100px;
  left: 20%;
}

#l8 {
  bottom: 160px;
  right: 45%;
}

#l9 {
  bottom: 100px;
  right: -10px;
}

.layer-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* skills */
.progress-wrapper {
  height: 130px;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
}

.wave::before,
.wave::after {
  content: "";
  position: absolute;
  width: 800px;
  height: 800px;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 45%;
  -webkit-transform: translateX(-50%) rotate(0);
  transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 6s linear infinite;
  animation: rotate 6s linear infinite;
  z-index: 10;
}

.wave::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transform: translateX(-50%) rotate(0);
  transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 10s linear -5s infinite;
  animation: rotate 10s linear -5s infinite;
  z-index: 20;
}

@-webkit-keyframes rotate {
  50% {
    -webkit-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
  }

  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes rotate {
  50% {
    -webkit-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
  }

  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
    transform: translateX(-50%) rotate(360deg);
  }
}

/* /skills */
/* education */
.edu-bg-image {
  position: absolute;
  left: 0;
  top: -300px;
  z-index: -1;
}

/* /education */
/* service */
.hover-bg-primary {
  transition: 0.3s ease;
}

.hover-bg-primary:hover {
  background: #0785c6;
}

.hover-bg-primary:hover * {
  color: #fff;
}

.active-bg-primary {
  transition: 0.3s ease;
  background: #0785c6;
}

.active-bg-primary * {
  color: #fff;
}

.hover-shadow {
  transition: 0.2s ease;
}

.hover-shadow:hover {
  box-shadow: 0px 18px 40px 8px rgba(224, 241, 255, 1) !important;
}

/* /service */
/* portfolio */
.hover-wrapper {
  overflow: hidden;
}

.hover-wrapper img {
  transition: 0.3s ease;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hover-wrapper:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hover-wrapper:hover .hover-overlay {
  opacity: 1;
  visibility: visible;
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.hover-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

/* /portfolio */
/* testimonial */
.testimonial-content strong {
  color: #2bfdff;
  font-weight: normal;
}

.testimonial-bg-shapes .container {
  position: relative;
  z-index: 1;
}

.testimonial-bg-shapes .bg-map {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.testimonial-bg-shapes .bg-shape-1 {
  position: absolute;
  left: 100px;
  top: -50px;
}

.testimonial-bg-shapes .bg-shape-2 {
  position: absolute;
  right: 150px;
  top: 50px;
}

.testimonial-bg-shapes .bg-shape-3 {
  position: absolute;
  right: 50px;
  top: 200px;
}

.testimonial-bg-shapes .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 200px;
}

.testimonial-bg-shapes .bg-shape-5 {
  position: absolute;
  right: 200px;
  bottom: -50px;
}

/* /testimonial */
/* footer */

.section-on-footer {
  margin-bottom: -250px;
}

.shadow-down {
  position: relative;
}

.shadow-down::before {
  position: absolute;
  content: "";
  box-shadow: 0px 0px 80.75px 14.25px rgba(224, 241, 255, 0.34);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

/* /footer */
.card-lg {
  flex-direction: row-reverse;
  align-items: center;
}

@media (max-width: 991px) {
  .card-lg {
    flex-direction: column;
  }
}

blockquote {
  display: inline-block;
  padding: 10px 20px;
  background: #eaeaea;
  border-left: 2px solid #0785c6;
  font-style: italic;
  font-size: 22px;
}

.content * {
  margin-bottom: 20px;
}

.content strong {
  font-family: "Poppins", serif;
  font-size: 22px;
  font-weight: normal;
  color: #000;
  display: inherit;
  line-height: 1.5;
}

.page-title-alt {
  padding: 350px 0 70px;
}

.border-thick {
  border: 10px solid;
}

.drag-lg-top {
  margin-top: -230px;
}

@media (max-width: 991px) {
  .drag-lg-top {
    margin-top: 0;
  }
}

.page-title-alt .container {
  position: relative;
  z-index: 1;
}

.page-title-alt .bg-shape-1 {
  position: absolute;
  left: 0;
  top: 0;
}

.page-title-alt .bg-shape-2 {
  position: absolute;
  left: 70px;
  top: 100px;
}

.page-title-alt .bg-shape-3 {
  position: absolute;
  left: 30%;
  top: 50px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-title-alt .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 100px;
}

.page-title-alt .bg-shape-5 {
  position: absolute;
  left: 40%;
  bottom: -25px;
}

.page-title-alt .bg-shape-6 {
  position: absolute;
  bottom: 100px;
  right: -100px;
}

.page-title {
  padding: 250px 0 150px;
}

.page-title .container {
  position: relative;
  z-index: 1;
}

.page-title .bg-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.page-title .bg-shape-2 {
  position: absolute;
  left: -20px;
  top: -10px;
}

.page-title .bg-shape-3 {
  position: absolute;
  left: 10%;
  top: 100px;
}

.page-title .bg-shape-4 {
  position: absolute;
  left: 50%;
  top: -20px;
}

.page-title .bg-shape-5 {
  position: absolute;
  left: 90px;
  bottom: -50px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-title .bg-shape-6 {
  position: absolute;
  right: 20%;
  bottom: -20px;
}

.page-title .bg-shape-7 {
  position: absolute;
  right: -220px;
  bottom: -100px;
}

.filter-controls li {
  cursor: pointer;
}

.filter-controls li.active {
  font-weight: bold;
}

.site-logo {
  width: 200px;
}

.we-hiring {
  position: absolute;
  right: 0px;
  top: 70px;
  z-index: 1000;
  transform: rotate(10deg);
  transform-origin: 50% 0 0;
  animation: hire 2.5s ease-in-out 0s normal forwards infinite;
}

@keyframes hire {

  0%,
  100% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

img {
  height: auto;
}

.font-60 {
  font-size: 60px;
}

.font-40 {
  font-size: 40px;
}

.font-34 {
  font-size: 34px;
}

.font-bold {
  font-weight: bold;
}

.typed {
  font-size: 40px;
}

.typed_wrap {
  position: relative;
  /*left: -100px;*/
}

.typed_wrap h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.typed_wrap h1 span {
  color: #fff;
}

.typed::after {
  content: "|";
  display: inline;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

/*Removes cursor that comes with typed.js*/
.typed-cursor {
  opacity: 0;
  display: none;
}

/*Custom cursor animation*/
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home-p {
  font-size: 16px;
  z-index: 2;
  position: relative;
  color: #fff;
}

.more-btn {
  display: inline-block;
  background-color: #ffffff;
  border: 0;
  color: #0785c6;
  padding: 12px 20px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 800;
  z-index: 2;
  position: relative;
}

.tech_h6_mr {
  margin: 0px 30px;
  font-size: 16px;
}

.more-btn:hover,
.more-btn:focus,
.more-btn:active {
  background-color: #ffffff;
  text-decoration: none;
  color: #0785c6;

  transition: all 0.3s;
}

.send-message-btn {
  display: inline-block;
  background-color: #ffffff;
  border: 0;
  font-size: 18px;
  color: #0785c6;
  padding: 10px 20px;
  letter-spacing: 1px;
  transition: all 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  z-index: 2;
  position: relative;
}

.send-message-btn:hover,
.send-message-btn:focus,
.send-message-btn:active {
  background-color: #253858;
  text-decoration: none;
  color: #ffffff;
  transition: all 0.3s;
}

.primary-color {
  color: #0785c6;
}

.paragraph-text {
  color: #657088;
  line-height: 25px;
  word-spacing: 5px;
}

.technologies-sub-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 27px;
  line-height: 2;
  margin-top: 15px;
}

.special-about-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 36px;
  line-height: 2;
}

.technologies-btn {
  text-transform: uppercase;
  margin-top: 50px;
}

.technologies-banner,
.technologies-banner-left {
  position: relative;
  z-index: 1;
}

.technologies-banner-left {
  margin-right: 60px;
}

.technologies-banner {
  margin-left: 60px;
}

.technologies-banner::after,
.technologies-banner-left:after {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 50px;
  background-color: #def9ff;
  position: absolute;
  z-index: -2;
  top: 5px;
}

.technologies-banner-left:after {
  right: -55px;
}

.technologies-banner::after {
  left: -55px;
}

.technologies-banner::before,
.technologies-banner-left:before {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 50px;
  background-color: #91cfe8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
  z-index: -1;
}

.technologies-banner::before {
  left: -29px;
}

.technologies-banner-left:before {
  right: -29px;
}

.technologies-banner img,
.technologies-banner-left img {
  max-width: 100%;
  border-radius: 30px;
}

.paragraph-technologies {
  font-size: 14px;
  line-height: 25px;
  color: grey;
  word-spacing: 5px;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1185px;
  }

  .container-fluid {
    max-width: 1653px;
  }
}

.custom-slick-dots {
  text-align: center;
  padding-left: 0;
  margin-bottom: 10px;
}

.custom-slick-dots li {
  display: inline-block;
  margin: 2px;
}

.custom-slick-dots li button {
  height: 6px;
  width: 25px;
  background: #0785c6;
  color: #0785c6;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s ease;
  border: 0;
}

.custom-slick-dots li.custom-slick-active button {
  background: #0785c6;
  width: 50px;
}

.technology-stack {
  /*background-image: url(../images/technology_stack_bg.webp);*/
  background-repeat: no-repeat;
  /*background-size: cover;*/
}

.bout-us-img {
  width: 70px;
  height: 70px;
  padding: 10px;
}

.about-us .card {
  border-radius: 10px;
}

.technology-stack-img {
  width: 88px;
  /*    height:70px;*/
  padding: 10px;
}

.technologies-stack-img {
  width: 130px;
  /*    height:70px;*/
  padding: 10px;
  margin-top: 28px;
}

.technology-stack .card {
  width: 125px;
  height: 123px;
  border-radius: 50%;
}

.technologies .card {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.technology-stack-row .col-md-2 {
  margin: 10px 0px;
}

.timezone-row .col-md-2 {
  margin: 10px 0px;
}

.cloud-animation .cloud {
  position: absolute;
  animation-name: cloud-animation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  left: 0;
  z-index: 1;
  top: -180px;
}

.cloud-animation .cloud1 {
  position: absolute;
  animation-name: cloud-animation;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 1s;
  left: -25%;
  top: -175px;
  z-index: 11;
}

@keyframes cloud-animation {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  50% {
    -webkit-transform: translateX(15%);
    transform: translateX(15%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.rocket-animation {
  top: -100px;
  position: relative;
  animation: fly 5s infinite;
}

@keyframes fly {
  from {
    top: 1px;
  }

  to {
    top: -150px;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0px, 0px, 0);
  }

  50% {
    transform: translate3d(0px, -10px, 0);
  }

  100% {
    transform: translate3d(0px, -50px, 0);
  }
}

.rocket-animation img {
  width: 100px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
}

.slick-prev {
  left: -55px !important;
  background-color: #1185c6;
}

.slick-next {
  right: -55px !important;
}

.slick-prev,
.slick-next {
  height: 50px;
  width: 50px;
}

.team-card {
  border: 10px solid;
  border-radius: 15px;

  background-color: #48b9f3;
}

.services-ul li {
  border: 2px solid #e8eff6;
  padding: 10px;
  border-radius: 100% 40% 100% 100%;
}

.services-ul li img {
  width: 56px;
}

.btn-light {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.footer-background {
  background-image: url(../images/footer/bottom_bgs.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-background .section {
  padding-top: 60px;
}

@media (min-width: 320px) and (max-width: 375px) {
  .footer-background {
    width: fit-content;
  }

  /* .footer-ui li a span {
    margin-left: -7px;
  } */
  .section_proud {
    width: 425px;
  }
}

.right-border {
  border-right: 3px solid #dee2e6 !important;
}

.social-btn .fa {
  font-size: 30px;
  /*width: 30px;*/
  text-align: center;
  text-decoration: none;
  margin: 5px;
  border-radius: 50%;
  background: #0785c6;
  color: white;
}

.social-btn .fa:hover {
  opacity: 0.7;
}

.col-md-3 {
  padding-right: 15px;
  padding-left: 38px;
}

.social-btn .fa-facebook {
  padding: 20px 25px;
}

.social-btn .fa-instagram {
  padding: 20px 20px;
}

.social-btn .fa-skype {
  padding: 20px 20px;
}

.social-btn .fa-linkedin {
  padding: 20px 20px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.bg-android {
  background-color: #36b39b;
}

.bg-php {
  background-color: #4f5b93;
}

.bg-node {
  background-color: #539e43;
}

.bg-laravel {
  background-color: #fb4f3b;
}

.bg-flutter {
  background-color: #807aff;
}

.bg-react {
  background-color: #53c1de;
}

.bg-contact-us {
  margin-top: -40px;
  background-color: #1185c6;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-weight-500 {
  font-weight: 500;
}

.car-btn {
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 1px;
  background-color: #339bd0;
}

.width-71-per {
  width: 71% !important;
}

.hr_txt_clr {
  color: #1185c6 !important;
  font-weight: 700;
}

.exp_life {
  background: aliceblue;
  padding-top: 50px;
  padding-bottom: 50px;
  font-weight: 600;
}

.width-50 {
  width: 50px;
}

/*.form-control:focus{
    background: #0785c6 !important;
    color :#FFF;
    border-bottom: 2px solid #FFF;
}*/
.contactus-img {
  width: 55px;
}

.contactus-ul li {
  border: 5px solid #e8eff6;
  padding: 20px;
  border-radius: 100% 15% 100% 100%;
  background: #e2e6ea;
  width: 130px;
}

.contactus-text {
  line-height: 20px;
  margin-top: 15px;
}

.contactus-text link {
  color: #657088;
}

.address-icon {
  float: right;
  margin-top: -35px;
}

.address-icon img {
  margin: 0px;
}

.address-text {
  line-height: 7;
}

.call-icon-li {
  width: 200px !important;
  height: 180px;
}

.call-icon-li-img {
  margin: 30px;
  width: 80px;
}

.call-icon-ul {
  margin-top: -50px;
}

.location-img {
  width: 45px;
}

.bg-map-ico {
  color: #1596dc !important;
}

.v-align-super {
  vertical-align: super;
}

.nav-item {
  color: white;
  display: inline-block;
  margin: 0;
  /* text-transform: uppercase; */
}

/* .nav-item:after {
  display: block;
  content: "";
  border-bottom: solid 3px rgb(255, 255, 255);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
} */

.nav-item:hover:after {
  transform: scaleX(1);
}

.nav-item.fromRight:after {
  transform-origin: 100% 50%;
}

.nav-item.fromLeft:after {
  transform-origin: 0% 50%;
}

.paragraph-technologie-box {
  font-size: 14px;
  line-height: 25px;
  color: grey;
  word-spacing: 5px;
  min-height: 200px;
}

.technology-box {
  border: 1px solid #ebe8e8;
  padding: 20px 20px 15px;
  height: auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.technology-box:hover {
  background: #def9ff;
  transform: translateY(-5px);
}

.technology-box-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 17px;
  line-height: 32px;
  margin-top: 45px;
}

/*  */

/* delivery home */

.delivery-skyline img {
  width: 270px;
  max-width: 100%;
  padding: 12px;
}

/* why choose section home */

.skyline_whychoose-section {
  padding: 50px 0 50px 0;
}

.skyline_container-1760 {
  max-width: 1800px;
  margin: auto;
  padding: 0 20px;
}

.skyline_container-1440 {
  max-width: 1440px;
  margin: auto;
  padding: 0 39px;
}

.skyline_table {
  display: table;
}

.skyline_whychoose-sec:first-child {
  border-left: none;
}

.skyline_whychoose-sec.skyline_whychoose-largersec img {
  max-width: 130px;
  width: 100%;
}

.skyline_whychoose-sec.skyline_whychoose-largersec div {
  width: calc(100% - 130px);
  text-align: left;
}

.skyline_inline {
  display: inline-block;
  vertical-align: middle;
}

.skyline_whychoose-largersec.skyline_whychoose-sec h4 {
  color: #253858;
  margin-left: 0;
}

.skyline_whychoose-sec h4 {
  color: #253858;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 10px;
}

.skyline_whychoose-sec.skyline_whychoose-largersec .skyline_inline p {
  max-width: 95%;
}

.skyline_whychoose-sec {
  position: relative;
  border-left: 2px solid #0785c6;
  display: table-cell;
  vertical-align: middle;
}

.skyline_whychoose-sec:nth-child(2).skyline_whychoose-smallsec img {
  max-width: 130px;
  width: 100%;
}

.skyline_whychoose-sec:first-child::after {
  border-right: none;
  border-bottom: none;
}

.skyline_whychoose-sec div {
  padding-left: 20px;
}

.skyline_whychoose-sec.skyline_whychoose-smallsec p {
  text-transform: capitalize;
}

.skyline_upwork-score p {
  margin-bottom: 0;
  color: #253858;
  font-weight: 600;
}

.skyline_upwork-score {
  text-align: left;
  max-width: 170px;
  width: 100%;
  margin: 0 auto;
}

.skyline_upwork-score .skyline_success-score {
  text-align: left;
  padding-left: 0 !important;
}

.skyline_success-score {
  margin: 5px 0;
  padding-left: 0 !important;
}

.skyline_success-score span.top_rated i {
  color: #14bff4;
  font-size: 14px;
  font-style: normal;
  font-family: "PT Sans", sans-serif;
  text-transform: uppercase;
  line-height: 14px;
  display: block;
  margin-bottom: 3px;
}

.skyline_success-score span.top_rated {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 7px;
}

.skyline_success-score img {
  max-width: 124px !important;
  width: 78px !important;
  display: inline-block;
  vertical-align: middle;
}

.skyline_success-score span.top_rated {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 7px;
}

.skyline_whychoose-sec:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform: rotate(-45deg) translateY(-50%);
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #0785c6;
  border-right: 2px solid #0785c6;
  background: #fff;
  margin-left: -1px;
}

.skyline_whychoose-sec.skyline_whychoose-smallsec {
  width: 16.9%;
  text-align: center;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec span.tecnolgy_icon.experience {
  background-position: 4px -70px;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec span.tecnolgy_icon {
  width: 40px;
  height: 30px;
  display: block;
  background: url("../images/home/choose_icon.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec .skyline_technolgy h4 {
  margin-bottom: 0;
}

.skyline_whychoose-sec h5,
.skyline_whychoose-sec ul {
  margin: 10px 0;
  color: #6c7c8e;
  font-weight: 700;
  font-size: 16px;
}

.skyline_whychoose-sec ul li {
  display: inline-block;
  list-style: none;
  color: #0d7aff;
}

.skyline_whychoose-sec.skyline_whychoose-smallsec p {
  text-transform: capitalize;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec .skyline_technolgy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec span.tecnolgy_icon.brain_experts {
  background-position: 4px -4px;
}

.skyline_whychoose-section .skyline_table .skyline_whychoose-sec span.tecnolgy_icon.projects_accomplished {
  background-position: 4px -38px;
}

@media only screen and (max-width: 1024px) {
  .skyline_whychoose-section .skyline_table {
    display: block;
    font-size: 0;
  }

  .skyline_whychoose-sec.skyline_whychoose-largersec {
    width: 100%;
    border-right: 0;
    text-align: center;
    display: block;
  }

  .skyline_whychoose-sec.skyline_whychoose-largersec div {
    width: calc(100% - 130px);
    text-align: left;
  }

  .skyline_whychoose-sec.skyline_whychoose-smallsec {
    border-left: none;
  }

  .skyline_whychoose-sec.skyline_whychoose-smallsec {
    width: 50%;
    margin: 25px;
    display: inline-block;
  }

  .wish_content .span {
    display: none;
  }
}

/* home industrial world wide */

.skyline-expertise-wrapper {
  padding: 6px 30px;
  position: relative;
  text-align: left;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.skyline-expertise-wrapper figure {
  line-height: 1;
}

.skyline-expertise-wrapper .shape-move {
  margin-top: -57px;
  z-index: -1;
  position: relative;
  transform: scale(1.7);
  transition: 0.3s all linear;
  top: -13px;
}

.skyline-expertise-wrapper p {
  margin: 0;
  padding-left: 25px;
  position: relative;
  margin-top: -20px;
  color: #253858;
  font-size: 16px;
  font-weight: 600;
}

.skyline-expertise-icon-gradient {
  display: none;
}

.skyline-expertise-wrapper figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  transition: 0.4s;
}

.skyline-expertise-wrapper figure span {
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.skyline-expertise-wrapper:hover .skyline-expertise-icon-gray {
  display: none;
}

.skyline-expertise-wrapper:hover .skyline-expertise-icon-gradient {
  display: block;
}

/*  */
@media screen and (max-width: 1074px) {
  .navbar-expand-lg .navbar-collapse {
    font-size: 11px;
  }
}

@media screen and (max-width: 1440px) {
  .technology-box-title {
    font-weight: bold;
    font-family: "Poppins", serif;
    font-size: 17px;
    margin-top: 45px;
  }

  .navigation {
    padding: 30px;
  }

  .navigation.nav-bg {
    padding: 0px 28px;
  }

  /* */
}

@media screen and (max-width: 1024px) {
  .layer {
    display: none;
  }

  .ly-img {
    display: block;
    width: 80% !important;
  }

  .long-sh-img {
    height: 997px;
  }

  .weara .item {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .technologies-sub-title {
    margin-top: 70px;
  }

  .marginTopSection {
    margin-top: 50px;
  }

  .technologies-banner-left,
  .technologies-banner {
    margin: 10px auto;
    width: 65%;
  }
}

@media screen and (max-width: 479px) {
  .technologies-sub-title {
    margin-top: 24px;
  }

  .technologies-banner-left:before {
    right: -14px;
  }

  .technologies-banner::before,
  .technologies-banner-left:before {
    border-radius: 35px;
  }

  .technologies-banner-left:after {
    right: -30px;
  }

  .technologies-banner::after,
  .technologies-banner-left:after {
    border-radius: 35px;
  }

  .technologies-banner::before {
    left: -14px;
  }

  .technologies-banner::after {
    left: -30px;
  }
}

@media screen and (max-width: 768px) {
  .layer {
    display: none;
  }

  .ly-img {
    display: block;
    width: 92% !important;
  }

  .long-sh-img {
    height: 987px;
  }

  .hero-area {
    padding-bottom: 667px;
  }
}

@media screen and (max-width: 866px) {
  .long-sh-img {
    height: 987px;
  }
}

@media screen and (max-width: 425px) {
  .layer {
    display: none;
  }

  .ly-img {
    display: block;
    width: 66% !important;
  }

  .long-sh-img {
    height: 764px;
  }

  .hero-area {
    padding-bottom: 396px;
  }
}

@media screen and (max-width: 768px) {
  .cloud-animation .cloud {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: none !important;
    animation-iteration-count: none !important;
    animation-timing-function: none !important;
  }

  .cloud-animation .cloud1 {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: none !important;
    animation-iteration-count: none !important;
    animation-timing-function: none !important;
  }

  .hero-area {
    padding-bottom: 667px !important;
    padding: 200px 0 150px;
  }

  .technology-stack-row .col-6 {
    margin: 10px !important;
    text-align: -webkit-center;
  }

  .timezone-row .col-6 {
    margin: 10px !important;
    text-align: -webkit-center;
  }
}

@media screen and (max-width: 640px) {
  .typed_wrap h1 {
    font-size: 35px !important;
  }

  .typed {
    font-size: 30px !important;
  }

  .hero-area {
    padding-bottom: 396px !important;
    padding: 230px 0 150px;
  }

  .mb-view {
    text-align: center !important;
  }

  .right-border {
    border: none !important;
    padding-bottom: 20px;
  }

  .team-card .text-white {
    font-size: 14px !important;
  }

  .technology-stack-row .col-6 {
    margin: 10px 0px !important;
    text-align: -webkit-center;
  }

  .timezone-row .col-6 {
    margin: 10px 0px !important;
    text-align: -webkit-center;
  }

  .section_heading h3 {
    font-size: 18px;
  }

  .standout_headline .headline {
    padding-left: 0px;
  }

  .headline p {
    padding-left: 0px;
    line-height: 26px;
    font-size: 14px;
  }

  .headline img {
    display: none;
  }

  .weara .item {
    display: block;
  }
}

@media screen and (max-width: 320px) {
  .hero-area {
    padding-bottom: 300px !important;
    padding: 230px 0 150px;
  }

  .team-card .text-white {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 466px) {
  .wish_content {
    display: inline-flex;
  }
}

@media screen and (max-width: 381px) {
  .wish_content {
    display: inline-flex;
  }
}

.backtotop #taketotop {
  bottom: 90px;
  right: 36px;
}

@media screen and (max-width: 375px) {
  .layer {
    display: none;
  }

  .ly-img {
    display: block;
    width: 65% !important;
  }

  .long-sh-img {
    height: 764px;
  }

  .hero-area {
    padding-bottom: 355px;
  }
}

@media screen and (max-width: 320px) {
  .layer {
    display: none;
  }

  .ly-img {
    display: block;
    width: 63% !important;
  }

  .long-sh-img {
    height: 668px;
  }

  .hero-area {
    padding-bottom: 300px;
  }
}

.backtotop {
  position: fixed;
  bottom: -80px;
  right: 12px;
  z-index: 1;
  height: 80px;
  width: 80px;
  transition: all 0.9s;
  border-radius: 50%;
  background-color: rgb(17 133 198 / 47%);
}

.backtotop::before {
  content: "";
  background-color: rgb(17 133 198 / 61%);
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 50px;
  -webkit-animation: pulsing1 2.3s infinite;
  animation: pulsing1 2.3s infinite;
}

.backtotop #taketotop.myrocket {
  visibility: visible;
  opacity: 1;
}

.backtotop #taketotop {
  position: fixed;
  bottom: 50px;
  z-index: 1;
  display: none;
  visibility: hidden;
  width: 35px;
  height: 70px;
  right: 34px;
  background: url(../images/home/backtoyop.webp) no-repeat 50% 0;
  transition: all 0.5s;
  background-size: contain;
}

.backtotop::after {
  content: "";
  background-color: #0785c6;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  border-radius: 50px;
}

.backtotop #taketotop:hover {
  height: 125px;
  transition: all 0.5s;
  right: 35px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f2f7;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(245, 245, 245, 0);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0785c6;
}

.btn-portfolio {
  background-color: #0785c6;
  border-radius: 50px;
}

.border-white-10 {
  border: 10px solid #f3f7fb;
}

.w-80 {
  width: 80px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #495057 !important;
}

/*jssor slider loading skin spin css*/
.jssorl-009-spin img {
  animation-name: jssorl-009-spin;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes jssorl-009-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*jssor slider arrow skin 106 css*/
.jssora106 {
  display: block;
  position: absolute;
  cursor: pointer;
}

.jssora106 .c {
  fill: #fff;
  opacity: 0.3;
}

.jssora106 .link {
  fill: none;
  stroke: #000;
  stroke-width: 350;
  stroke-miterlimit: 10;
}

.jssora106:hover .c {
  opacity: 0.5;
}

.jssora106:hover .link {
  opacity: 0.8;
}

.jssora106.jssora106dn .c {
  opacity: 0.2;
}

.jssora106.jssora106dn .link {
  opacity: 1;
}

.jssora106.jssora106ds {
  opacity: 0.3;
  pointer-events: none;
}

/*jssor slider thumbnail skin 101 css*/
.jssort101 .p {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: #000;
}

.jssort101 .p .cv {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  box-sizing: border-box;
  z-index: 1;
}

.jssort101 .link {
  fill: none;
  stroke: #fff;
  stroke-width: 400;
  stroke-miterlimit: 10;
  visibility: hidden;
}

.jssort101 .p:hover .cv,
.jssort101 .p.pdn .cv {
  border: none;
  border-color: transparent;
}

.jssort101 .p:hover {
  padding: 2px;
}

.jssort101 .p:hover .cv {
  background-color: rgba(0, 0, 0, 6);
  opacity: 0.35;
}

.jssort101 .p:hover.pdn {
  padding: 0;
}

.jssort101 .p:hover.pdn .cv {
  border: 2px solid #fff;
  background: none;
  opacity: 0.35;
}

.jssort101 .pav .cv {
  border-color: #fff;
  opacity: 0.35;
}

.jssort101 .pav .link,
.jssort101 .p:hover .link {
  visibility: visible;
}

.jssort101 .t {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0.6;
}

.jssort101 .pav .t,
.jssort101 .p:hover .t {
  opacity: 1;
}

/* Dropdown */

li link,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li.custom-dropdown {
  display: inline-block;
}

.custom-dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.custom-dropdown-content link {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  color: #0785c6;
}

.custom-dropdown-content link:hover {
  background-color: #0785c6;
  color: #fff !important;
}

.custom-dropdown:hover.custom-dropdown-content {
  display: block;
}

.about-us-height {
  height: 355px;
}

.about-we-us-height {
  border-radius: 20px;
}

/* digital development */

.selectionSecurity {
  margin-bottom: 60px;
}

.paragraph-security {
  font-size: 12px;
  line-height: 25px;
  color: rgb(22, 57, 97);
  word-spacing: 5px;
}

.security-sub-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 35px;
  margin-top: 15px;
}

.item {
  display: flex;
  margin-top: 40px;
}

/* deliver section about */

.country-section {
  width: 100%;
  display: inline-block;
  position: relative;
  background-image: url(../images/about/deliver_img_bg.webp);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.cpb {
  padding-bottom: 100px;
}

.cpt {
  padding-top: 100px;
}

.country-section .container {
  max-width: 800px;
  margin: 0 auto;
}

.country-header h2,
.country-header h3 {
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 32px;
  line-height: normal;
}

.country-menu-box {
  text-align: center;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
}

.country-menu-box li {
  display: inline-block;
  list-style: none;
  text-align: center;
  padding-left: 4px;
  padding-bottom: 6px;
}

.country-menu-box li p {
  padding-right: 14px;
  color: #fff;
  text-decoration: none;
  line-height: 26px;
  font-size: 20px;
  transition: all 0.5s ease;
}

/* proud moment */

.section_proud {
  position: relative;
  background-color: #f4faff;
  padding: 80px 0;
}

.client_main {
  position: relative;
}

.standout_headline {
  background: #fff;
  padding: 40px 30px;
  margin-top: -46px;
  box-shadow: 0 0 20px #0000001a;
}

.section_heading {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  margin-bottom: 40px;
  font-family: "Poppins", serif;
  font-size: 36px;
  line-height: 4px;
  color: #2c2d2d;
  margin-top: 0;
}

.section_heading::before {
  position: absolute;
  display: block;
  content: "";
  background: url("../images/about/proud_img_dot.webp") no-repeat 0 0;
  background-size: cover;
  width: 85px;
  height: 8px;
  bottom: 0;
}

.headline {
  position: relative;
  font-family: "Poppins", serif;
  font-size: 18px;
  line-height: 36px;
  padding-left: 40px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.standout_content {
  padding: 40px 20px 0;
}

.client_main .row {
  margin: 0;
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 768px) {
  .about-us-height {
    height: auto;
    margin-top: 30px;
  }
}

.h-260 {
  height: 260px;
}

/* .nav-item:after {
  border-bottom: none !important;
} */

/* .navbar-nav .active {
  border-bottom: solid 3px #0785c6;
} */

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-7 {
  padding-top: 7px !important;
}

@media screen and (max-width: 980px) {
  section.testimonials {
    padding-top: 0px !important;
  }
}

section.testimonials {
  position: relative;
  padding-bottom: 70px;
}

section {
  overflow: hidden;
}

section.testimonials .testimonial-illustration {
  position: absolute;
  right: 0px;
  top: -1px;
  width: 48vw;
  height: 900px;
  background-image: linear-gradient(to bottom right, #2e85c6, #359bd1);
}

@media screen and (max-width: 480px) {
  section.testimonials .testimonial-illustration {
    height: 449px;
  }
}

@media screen and (max-width: 680px) {
  section.testimonials .testimonial-illustration {
    top: 170px;
  }
}

@media screen and (max-width: 980px) {
  section.testimonials .testimonial-illustration {
    top: 230px;
    width: 100%;
    height: 800px;
  }
}

.ornament-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1115px) {
  .ornament-wrapper {
    overflow: hidden;
  }
}

/*.container {
    max-width: 1115px;
    position: relative;
}*/
@media screen and (max-width: 680px) {
  section.testimonials .owl-carousel {
    margin-top: 30px;
  }
}

@media screen and (max-width: 980px) {
  section.testimonials .owl-carousel {
    left: 0px;
  }
}

section.testimonials .owl-carousel {
  margin-top: 0px;
  position: relative;
  left: 165px;
}

@media screen and (max-width: 480px) {
  section.testimonials .owl-carousel>.owl-stage-outer {
    padding-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  section.testimonials .owl-carousel>.owl-stage-outer {
    padding-bottom: 0px;
    padding-right: 150px;
  }
}

@media screen and (max-width: 680px) {
  section.testimonials .owl-carousel>.owl-stage-outer {
    padding-top: 150px;
  }
}

@media screen and (max-width: 680px) {
  section.testimonials .owl-carousel>.owl-stage-outer {
    padding: 40px 0px;
    width: 100%;
  }
}

@media screen and (max-width: 980px) {
  section.testimonials .owl-carousel>.owl-stage-outer {
    padding-top: 120px;
  }
}

section.testimonials .owl-carousel>.owl-stage-outer {
  padding-left: 220px;
  overflow: visible;
  position: relative;
  padding-bottom: 40px;
  padding-top: 20px;
  overflow: visible;
  padding: 40px 45px;
  width: 300%;
}

@media screen and (max-width: 390px) {
  section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
    left: -16%;
  }
}

@media screen and (max-width: 480px) {
  section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
    left: -21%;
  }
}

@media screen and (max-width: 680px) {
  section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
    left: -75px;
  }
}

@media screen and (max-width: 980px) {
  section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
    left: -125px;
  }
}

@media screen and (max-width: 1115px) {
  section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
    left: -250px;
  }
}

section.testimonials .owl-carousel>.owl-stage-outer .owl-stage {
  left: 210px;
}

section.testimonials .card.card-carousel {
  min-height: 250px;
}

@media screen and (max-width: 480px) {
  .card.card-carousel {
    width: 280px;
    min-height: 195px;
  }
}

@media screen and (max-width: 680px) {
  .card.card-carousel {
    width: 320px;
    min-height: 194px;
  }
}

.card.card-carousel {
  width: 486px;
  min-height: 344px;
  padding: 0px;
  text-align: left;
  box-shadow: 0px 22px 30px rgb(27 23 66 / 10%);
}

.owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

@media (min-width: 992px) {
  .ml-res {
    margin-left: 25%;
  }
}

.testimonials-box {
  border-radius: 5px;
  border-color: transparent;
  margin: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 22px 30px rgb(27 23 66 / 10%);
}

@media (max-width: 768px) {
  .col-md-6 {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  top: 48%;
  width: 100%;
}

.owl-theme .owl-controls .owl-buttons .owl-prev {
  content: "\f104";
  font-family: fontawesome;
  position: absolute;
  top: -30px;
  left: -200px;
  font-size: 40px;
  color: #2e85c6 !important;
  font-weight: 100;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.owl-theme .owl-controls .owl-buttons .owl-next {
  content: "\f105";
  font-family: fontawesome;
  position: absolute;
  top: -30px;
  right: -200px;
  font-size: 40px;
  color: #2e85c6 !important;
  font-weight: 100;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.testimonials-box .card-header {
  background: #ffffff;
  padding: 30px 30px 0px 30px;
  border: none;
}

.user-profile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.testimonials-box .card-header .user-profile>span {
  display: inline-block;
}

.testimonials-box .card-header .user-profile>span>img {
  width: 53px;
  margin-right: 20px;
}

.testimonials-box .card-header .user-profile>span>h6 {
  margin-top: 0px;
  color: #3f4658;
  font-weight: bold;
}

.testimonials-box .card-header .user-profile>span>small {
  font-size: 11px;
  color: #96a1bf;
  line-height: 10px;
  display: inline-block;
  position: relative;
  bottom: 6px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.default-img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  padding-top: 6px;
  border: 2px solid #1676d0;
  background-color: lightblue;
}

.testimonials-box .card-body {
  padding: 23px 30px 30px 30px;
}

.card.card-carousel .card-body>p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
}

.testimonials .card.card-carousel .card-body {
  padding-bottom: 15px;
}

.subheadfooing {
  font-weight: bold;
}

@media screen and (max-width: 680px) {
  .testimonials-box {
    width: 320px;
    min-height: 194px;
  }

  .testimonials-box .card-header {
    padding-left: 17px;
    padding-right: 17px;
  }

  .testimonials-box .card-body {
    padding-top: 11px;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 20px;
  }

  .testimonials .card.card-carousel .card-body {
    padding-bottom: 10px;
  }

  .testimonials-box .card-body p {
    font-weight: 300;
    font-size: 8px;
    line-height: 20px;
  }

  .card.card-carousel .card-body>p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
  }
}

@media screen and (max-width: 480px) {
  .card.card-carousel {
    width: 280px;
    min-height: 195px;
  }

  section.testimonials .owl-carousel>.owl-stage-outer {
    padding-bottom: 0px;
    padding-right: 150px;
  }

  section.testimonials.testimonial-pricing .owl-carousel>.owl-stage-outer {
    padding-top: 50px;
  }

  .card.card-carousel .card-header .user-profile>span>h6 {
    font-size: 9px;
    line-height: 6px;
  }

  .card.card-carousel .card-header .user-profile>span>h6+small {
    font-size: 6.5px;
  }

  .card.card-carousel .card-body>p {
    font-weight: 500;
  }

  .card.card-carousel .card-header .user-profile>span>img {
    height: 28px;
    width: 28px;
    margin-right: 5px;
  }

  .video {
    width: 250px;
  }
}

.testimonial-illustration>img {
  width: 100% !important;
  /*height: 750px !important;*/
}

.testimonials-text {
  padding-top: 40px;
}

.user-profile>span>img {
  border-radius: 50%;
  border: 3px #2e85c6 solid;
}

.owl-nav>button {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  box-shadow: 0px 12px 24px rgb(221 221 236 / 50%);
  margin-right: 20px;
  font-size: 24px !important;
  color: #2e85c6 !important;
}

@media screen and (min-width: 768px) {
  .img-team {
    margin-top: -75px !important;
  }

  .col-md-2 {
    max-width: none;
  }
}

.footer-bottom {
  display: flex;
}

.footer-bottom ul {
  display: flex;
  margin: 0;
  margin-left: auto;
}

.country-flag {
  display: flex;
}

.dm_width {
  width: 250px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-bottom ul li link {
    font-size: 10px;
  }

  .footer-bottom p {
    font-size: 10px;
  }

  .footer-ui {
    font-size: 13px;
  }

  .dropdown-col-2 ul.dropdown-menu li {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background: none;
  }

  .dropdown-col-2 ul.dropdown-menu {
    width: auto;
  }

  ul.dropdown-menu {
    padding: 0px;
    box-shadow: unset !important;
  }

  .field {
    color: #2f2c2c;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }

  ul.dropdown-menu li .dropdown-item-title {
    color: #0785c6;
  }

  ul.dropdown-menu li .dropdown-item-title .h5 .img {
    display: none;
  }

  .dropdown-menu:after {
    border-bottom: none;
  }

  /* .navbar-nav .active {
    border-bottom: none;
    color: #fff;
  } */
  .backend-ch {
    margin-top: 20px;
  }

  .bottom-database {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .footer-bottom {
    display: block;
    margin-left: -40px;
    text-align: center;
  }

  .dropdown-col-2 ul.dropdown-menu li {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background: none;
  }

  .dropdown-col-2 ul.dropdown-menu {
    width: auto;
  }

  ul.dropdown-menu {
    padding: 0px;
    box-shadow: unset !important;
  }

  .field {
    color: #2f2c2c;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }

  ul.dropdown-menu li .dropdown-item-title {
    color: #0785c6;
  }

  .dropdown-menu:after {
    border-bottom: none;
  }

  /* .navbar-nav .active {
    border-bottom: none;
    color: #fff;
  } */
  .backend-ch {
    margin-top: 20px;
  }

  .bottom-database {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .cloud-animation .cloud {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: none !important;
    animation-iteration-count: none !important;
    animation-timing-function: none !important;
  }

  .cloud-animation .cloud1 {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: none !important;
    animation-iteration-count: none !important;
    animation-timing-function: none !important;
  }

  .hero-area {
    padding-bottom: 667px !important;
    padding: 200px 0 150px;
  }

  .technology-stack-row .col-6 {
    margin: 10px !important;
    text-align: -webkit-center;
  }

  .timezone-row .col-6 {
    margin: 10px !important;
    text-align: -webkit-center;
  }
}

@media screen and (max-width: 640px) {
  .typed_wrap h1 {
    font-size: 35px !important;
  }

  .typed {
    font-size: 30px !important;
  }

  .hero-area {
    padding-bottom: 396px !important;
    padding: 230px 0 150px;
  }

  .mb-view {
    text-align: center !important;
  }

  .right-border {
    border: none !important;
    padding-bottom: 20px;
  }

  .team-card .text-white {
    font-size: 14px !important;
  }

  .technology-stack-row .col-6 {
    margin: 10px 0px !important;
    text-align: -webkit-center;
  }

  .timezone-row .col-6 {
    margin: 10px 0px !important;
    text-align: -webkit-center;
  }
}

@media screen and (max-width: 320px) {
  .hero-area {
    padding-bottom: 300px !important;
    padding: 230px 0 150px;
  }

  .team-card .text-white {
    font-size: 12px !important;
  }
}

.backtotop #taketotop {
  bottom: 90px;
  right: 36px;
}

/* Our-service Part */

.services-body h5 {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 20px;
  color: #253858;
  text-align: center;
  margin-top: 14px;
}

.services-body p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #828282;
  margin-top: 20px;
}

.service_btn {
  width: 140px;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #0785c6;
}

.career_btn {
  width: 180px;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #339bd0;
}

/* Our-portfolio Part */

.portfolio-body h5 {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 20px;
  color: #0785c6;
}

.portfolio-body p {
  font-size: 14px;
  font-weight: 400;
  color: #828282;
}

.portfolio_btn {
  width: 230px;
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #0785c6;
}

.our_all_portfolio_btn {
  width: 230px;
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", serif;
  border-radius: 20px;
  border: 2px solid #0785c6;
  color: #0785c6;
  background-color: #ffffff;
}

.actived {
  background-color: #0785c6;
  color: #ffffff;
}

.contact_btn {
  width: 180px;

  margin-top: 10px;
  margin-left: 230px;
  /* width: 400px; */
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #0785c6;
}

.contacts_btn {
  width: 200px;
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #0785c6;
}

.contactd_btn {
  width: 200px;
  justify-content: center;
  align-items: center;
  position: "relative";
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", serif;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  background-color: #0785c6;
}

.client_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 1px;
  color: #828282;
  font-family: "Poppins", serif;
}

.jkjk {
  border-top: 20px;
  padding: 50px;
  border-radius: 10px;
  border: 2px solid #0785c6;
}

/* Blog */

.blog-img {
  width: 100%;
}

.blog-text {
  margin-top: 15px;
  text-align: left;
  font-weight: 600;
}

.blog-height {
  height: 360px;
}

.blog-btn {
  font-weight: 400 !important;
  padding: 8px 25px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

ul>li>.popular-list {
  color: inherit !important;
  padding: 0;
  vertical-align: text-top;
}

/* life skyline */
.custom-container {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 1440px;
}

@media (min-width: 1800px) {
  .custom-container {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 1440px;
  }
}

@media (min-width: 1200px) {
  .section_sky_life li .left_column {
    width: 100%;
  }
}

.ul_sky_life {
  padding: 0;
  margin: 0 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  position: relative;
  margin-top: 40px;
}

.section_sky_life {
  padding-top: 0px !important;
}

.section_sky_life .life_cover {
  display: flex;
  position: relative;
}

.section_sky_life li .left_column {
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.section_sky_life li .right_column {
  padding-left: 70px;
  padding-right: 0;
  position: relative;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
}

.sky_ul_content {
  max-width: 50%;
  padding-right: 30px;
}

.ul_sky_life:after {
  position: absolute;
  left: 50%;
  background: rgba(255, 255, 255, 0.23);
  width: 2px;
  height: 100%;
  content: " ";
  margin-left: -1px;
}

.section_box .ul_sky_life:after {
  background: #cbcbcb;
}

.section_sky_life li {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 45px;
}

.sky_ul_content {
  max-width: 50%;
  padding-right: 30px;
}

.section_sky_life li .life_text h3 {
  margin-bottom: 0px;
  position: absolute;
  left: 70px;
  top: 30px;
  font-size: 20px;
  text-transform: capitalize;
}

.global_h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 5px;
  text-align: center;
}

.life_inner {
  padding: 20px;
  margin-top: 60px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.section_sky_life li .life_text h3 span {
  color: #22a1e5;
}

.section_sky_life li .life_cover:before {
  content: "";
  background: url(../images/life_skyline/camera.webp);
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 28px;
  z-index: 999;
  width: 30px;
  height: 24px;
}

.section_sky_life li .bg_purpal:after {
  background: #6548c8;
}

@media (min-width: 1800px) {
  .section_sky_life li .life_text h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1800px) {
  .global_h2 {
    font-weight: 700;
    color: #202020;
    font-size: 30px;
    font-family: "Poppins", serif;
    margin: 0 0 35px 0;
  }
}

.global_h2 {
  font-weight: 600;
  color: #2fa5e0;
  font-family: "Poppins", serif;
  margin: 0 0 25px 0;
}

.section_sky_life li .left_column.life_text h3 {
  left: auto;
  right: 50px;
}

.global_h2 img {
  max-width: 35px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.section_sky_life li .life_cover:after {
  position: absolute;
  content: " ";
  height: 80px;
  width: 80px;
  left: 50%;
  top: 12px;
  border-radius: 50%;
  z-index: 1;
  margin-top: -12px;
  /*background: #22a1e6;*/
  border: 5px solid #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section_sky_life li .life_image img {
  width: 100%;
}

.section_sky_life li .bg_purpal:after {
  background: #6548c8;
}

.section_sky_life li .bg_green:after {
  background: #66b427;
}

input,
input::placeholder {
  font-size: 18px;
  color: "#b7b7b7";
}

.optgroup {
  font-size: 15px;
}

.radio-content {
  display: none;
}

/* services */
.services-btn {
  display: inline-block;
  background-color: #dde4eb;
  border: 0;
  font-weight: bold;
  width: 25%;
  color: #0785c6;
  padding: 10px 20px;
  letter-spacing: 1px;
  transition: all 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  z-index: 2;
  position: relative;
  margin-top: 50px;
}

.services-sub-title {
  font-weight: bold;
  font-family: "Poppins", serif;
  font-size: 27px;
  line-height: 2;
  margin-top: 15px;
}

/* .services-sub-title2 {
  font-weight: bold;
  font-family: "Poppins";
  font-size: 27px;
  line-height: 2;
} */
.services-primary-btn {
  text-transform: uppercase;
  margin-top: 50px;
}

.services-banner,
.services-banner-left {
  position: relative;
  z-index: 1;
}

.services-banner-left {
  margin-right: 100px;
}

.services-banner {
  margin-left: 60px;
}

.services-banner::after,
.services-banner-left:after {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 50px;
  background-color: #def9ff;
  position: absolute;
  z-index: -2;
  top: 5px;
}

.services-banner-left:after {
  right: -55px;
}

.services-banner::after {
  left: -55px;
}

.services-banner::before,
.services-banner-left:before {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 50px;
  background-color: #91cfe8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
  z-index: -1;
}

.services-banner::before {
  left: -29px;
}

.services-banner-left:before {
  right: -29px;
}

.services-banner img,
.services-banner-left img {
  max-width: 100%;
  border-radius: 30px;
}

/*  */
.paragraph-services {
  font-size: 14px;
  line-height: 25px;
  color: grey;
  word-spacing: 5px;
}

.list-services {
  font-weight: bold;
  margin-left: 15px;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1185px;
  }

  .container-fluid {
    max-width: 1653px;
  }
}

/* mobile app services */
.mobile-app-banner-left {
  margin-right: 100px;
}

.mobile-li {
  padding: 20px;
  font-weight: 600;
  font-size: 15px;
}

.mobile-li span {
  transition: ease-in-out 0.3s;
}

.active-tabs {
  padding: 15px;
  border-bottom: 5px solid #0785c6;
  font-size: 20px;
  font-weight: 600;
  transition: ease-in-out 0.3s;
}

.active-content {
  display: inline-flex;
}

.content {
  display: none;
}

.true_icon {
  width: 25px;
  height: 25px;
  margin: 0px 10px;
}

.shadow-box {
  padding-top: 80px;
  width: 55%;
  margin: 0px 450px;
}

.modal-li {
  padding: 0px 100px;
}

/* text see more */
.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: #1676d0;
  cursor: pointer;
  font-weight: bold;
}

.fixed-navbar {
  background-color: rgb(242, 252, 255);
  border-top: 2px solid #278de742;
  border-bottom: 2px solid #278de742;
  height: 65px;
  font-weight: 700;
  margin-top: 20px;
  z-index: 100;
  top: 55px;
  position: sticky;
}

.engagement_content .engagement_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-radio {
  font-size: 15px;
  font-weight: bold;
  color: #0785c6;
}

.active-radio-content {
  display: inline-flex;
}

.radio-content {
  display: none;
}

@media (max-width: 768px) {
  .fixed-navbar {
    display: inline-flex;
  }

  .navbar-nav-fix {
    display: block;
  }
}

@media (max-width: 375px) {
  .fixed-navbar {
    display: none;
  }

  /* .navbar-nav-fix {
    display: none;
  } */
  .hero-area-mobile {
    background-color: #0785c6;
  }

  .explore-btn {
    width: 180px;
    height: 50px;
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .explore-btn {
    margin-bottom: 10px;
  }

  .modal-li {
    padding: 0px 65px;
  }

  .text-center {
    margin-left: 10px;
  }

  /* .more-btn {
    background-color: #000;
   } */
}

@media (max-width: 768px) {
  .hero-area-mobile {
    padding-bottom: 627px !important;
    padding: 140px 0 150px;
  }

  .explore-btn {
    margin: 10px;
  }

  .modal-li {
    padding: 0px 25px;
  }
}

@media (max-width: 425px) {
  .explore-btn {
    width: max-content;
    margin-bottom: 10px;
    margin-left: -7px;
  }

  #overview {
    margin-top: -75px;
  }

  .hero-area-mobile {
    padding-bottom: 250px !important;
    padding: 140px 0 150px;
  }

  .modal-li {
    padding: 0px 3px;
  }

  .modal-ui {
    margin-left: -60px;
    font-size: 13px;
  }

  .active-tabs {
    border-bottom: 5px solid #0785c6;
    font-size: 16px;
  }

  .fixed-navbar {
    display: none;
  }

  .tech-ul {
    overflow: auto;
    max-width: 402px;
  }

  .ml-100 {
    margin-left: 30px !important;
  }

  .para {
    text-align: center;
  }
}

@media (max-width: 375px) {
  .explore-btn {
    width: 395px;
    height: fit-content;
  }
}

@media (max-width: 320px) {
  .ml-100 {
    margin-left: -26px !important;
  }
}

/* contact */
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .contact_img {
    display: inline-flex !important;
  }
}

@media only screen and (min-width: 1210px) and (max-width: 1440px) {
  .rightdiv {
    margin-left: -170px;
  }

  .leftdiv {
    width: 400px;
    margin-left: 30px;
  }

  .contact_img {
    display: inline-flex !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .display {
    display: block;
  }

  /* .maprow {
    display: flex;
  } */
  /* .footerrow {
    display: flex;
  } */
  .rightdiv {
    margin-left: 30%;
  }

  .leftdiv {
    width: 500px;
    margin-left: 55%;
  }

  .contact_img {
    display: inline-flex !important;
  }

  .radio {
    width: max-content;
  }

  .contact_btn {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1010px) {
  .leftdiv {
    width: max-content;
    margin-left: 25%;
  }

  .contact_btn {
    margin-bottom: 10px;
  }

  .contact_img {
    display: inline-flex !important;
  }

  /* .fileBtn {
    width: 95% !important;
  } */
  /* .message {
    width: 95% !important;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .hero-area {
    width: 994px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .hero-area {
    width: 994px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .hero-area {
    width: 1036px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 992px) {
  .engagement_content .engagement_div {
    display: block;
  }

  .active-tabs {
    border-bottom: 5px solid #0785c6 !important;
  }

  .modal-li .tab {
    border-bottom: none;
    font-size: 12px;
    padding: 14px;
  }

  .wish_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button_content {
    display: none;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .contact_btn {
    margin-left: 200px;
    margin-bottom: 10px;
  }

  .hero-area {
    width: 776px;
  }

  .rightdiv {
    width: max-content;
    margin-left: 3%;
  }

  .leftdiv {
    /* width: max-content; */
    margin-left: 12%;
  }

  .contact_img {
    display: flex !important;
  }

  .fileBtn {
    width: 100% !important;
  }

  .message {
    width: 100% !important;
  }

  .description {
    width: 203% !important;
  }
}

@media only screen and (min-width: 380px) and (max-width: 425px) {
  .country {
    max-width: 50% !important;
  }

  .message {
    max-width: 50% !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
  .testimonials {
    width: 450px;
  }

  .rightdiv {
    margin-left: 9%;
  }

  .section {
    width: 440px;
  }

  .hero-area {
    width: 450px;
  }

  .contact_btn {
    margin-left: -240px;
    margin-bottom: 10px;
    margin-top: 100px;
  }

  .country {
    width: 92% !important;
  }

  .message {
    width: 92% !important;
  }

  .description {
    width: 92% !important;
  }

  .fileBtn {
    width: 92% !important;
  }

  .contact_imgs {
    display: block !important;
  }
}

.services-body .react-clock__second-hand__body {
  background-color: #0785c6;
}

.services-body .react-clock__mark__body {
  background-color: #0785c6;
}

.services-body .react-clock__hand__body {
  background-color: #253858;
}

.services-body .react-clock__face {
  border: 1px solid #0785c6;
}

.partnership-btn {
  display: inline-block;
  background-color: #0785c6;
  border: 0;
  margin-top: 20px;
  color: #fff;
  padding: 12px 20px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 800;
  z-index: 2;
  position: relative;
}

.partnership-btn:hover {
  color: #fff;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .get-quote-btn {
    left: 10px !important;
  }
}

@media only screen and (min-width: 1416px) and (max-width: 1440px) {
  .contact_imgs_main_div {
    margin-left: -45px;
  }

  .width {
    width: 275px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .width {
    width: 350px !important;
  }
}

.engagement-modal-tabs .nav-tabs {
  border: 0;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.engagement-modal-tabs {
  padding: 30px 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.partnership-tabs-row .engagement-modal-tabs .nav-tabs li.nav-item a {
  min-height: 75px;
  padding: 0 0 20px 0;
}

.engagement-modal-tabs .nav-tabs li.nav-item a.active,
.engagement-modal-tabs .nav-tabs li.nav-item a:hover {
  border-color: #0785c6;
  color: #0785c6;
}

.engagement-modal-tabs .nav-tabs li.nav-item a {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #253858;
  background: transparent;
  border: 0;
  border-bottom: 3px solid #ecf2f7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 30px 0;
  transition: ease-in-out 0.3s;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.engagement-modal-tabs .nav-tabs li.nav-item a span {
  line-height: 27px;
  margin-left: 10px;
}

.career-content {
  line-height: 1.8;
  color: #7a849b;
  font-size: 17px;
}

.career-content-join {
  line-height: 1.8;
  font-size: 18px;
  font-weight: 600;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

img.vert-move {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}